.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-logo {
  height: 40vmin;
}

.Logo-button{
  background: transparent;
  border: none;
}

.App-text {
  position: absolute;
  text-align: left;
  /* Apply animation to this element */	
  -moz-animation: App-text 5s linear infinite alternate;
  -webkit-animation: App-text 5s linear infinite alternate;
  animation: App-text 5s linear infinite alternate;
 }
 /* Move it (define the animation) */
 @-moz-keyframes App-text {
  0%   { -moz-transform: translateX(80%); }
  100% { -moz-transform: translateX(10%); }
 }
 @-webkit-keyframes App-text {
  0%   { -webkit-transform: translateX(80%); }
  100% { -webkit-transform: translateX(10%); }
 }
 @keyframes App-text {
  0%   { 
  transform: translateX(80%); 		
  }
  100% { 
  transform: translateX(10%); 
  }
 }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Reload-button:hover {
  animation: logo-spin .5s linear 3;
  -webkit-animation: logo-spin .5s linear 3;
  background: transparent;
  border: none;
}
